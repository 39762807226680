.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f6f6f6;
}

.content {
  display: block;
  vertical-align: middle;
  padding: 30px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0 0 0 / 5%);
  border-radius: 6px;
}

.content h1 {
  text-align: center;
}

.content p {
  text-align: center;
}

.content p:last-child {
  margin-bottom: 0;
}
