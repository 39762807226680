@import 'reset';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';

html {
  min-height: 100vh;
  font-size: 16px;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.625;
  color: #222;
  background: #fff;
  min-height: 100vh;
  font-size: 0.9rem;
}

#root {
  min-height: 100vh;
}

.container {
  margin: 0 auto;
  max-width: 1380px;
}

.content {
  padding: 1.4rem 0;
}

a {
  color: #337ab7;
}

h1 {
  line-height: 1.2;
  font-size: 1.2rem;
  font-weight: bold;
}

.breadcrumbs {
  list-style: none;
  line-height: 1;
  margin: 0 0 1rem;

  li {
    margin: 0;
    padding: 0;
    display: inline-block;
    color: #999;
    font-size: 0.9rem;

    a {
      margin: 0;
      padding: 0;
      text-decoration: none;

      &::after {
        content: '/';
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        color: #aaa;
      }
    }
  }
}

.responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: '';
  }

  video,
  img,
  iframe {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &.responsive-16x9 {
    &::before {
      padding-top: calc(9 / 16 * 100%);
    }
  }
}
