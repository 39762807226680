.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 8px 0 rgb(0 0 0 / 20%);

  .content {
    padding: 15px 0;
    display: flex;
    flex-flow: row wrap;

    .logo {
      font-size: 1.4rem;
      line-height: 0.8;
      font-weight: bold;
      margin-right: 20px;

      a {
        color: #000;
        text-decoration: none;
      }
    }

    .name {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.4;
    }

    nav {
      margin-left: auto;
    }
  }
}
